<template>
  <b-sidebar
    id="add-player-sidebar"
    :visible="isAddPlayerSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-player-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Добавить игрока
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <b-form
        class="p-2"
        @submit.prevent="onSubmit"
        @reset.prevent="resetForm"
      >

        <b-form-group
          label="Игроки"
          label-for="players"
        >
          <v-select
            v-model="selectedPlayers"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="players"
            :reduce="val => val.id"
            :get-option-label="option => option.profile.fio"
            :clearable="false"
            multiple
            input-id="players"
          >
            <span slot="no-options">
              Нет данных
            </span>
          </v-select>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Добавить
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Отменить
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import { ref, watch, nextTick } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddPlayerSidebarActive',
    event: 'update:is-add-player-sidebar-active',
  },
  props: {
    isAddPlayerSidebarActive: {
      type: Boolean,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const players = ref([])
    const selectedPlayers = ref([])

    watch(() => props.isAddPlayerSidebarActive, () => {
      if (props.isAddPlayerSidebarActive) {
        store.dispatch('app-group/fetchPlayers', {
          api: 'http://api.bc-sport.ru/api/players',
          params: {
            branche_id: props.group.branche_id,
            category_id: props.group.category_id,
            not_in_group: props.group.id,
          },
        })
          .then(response => { players.value = response.data.data })
          .catch(() => {
            players.value = []
          })
      } else {
        selectedPlayers.value = []
      }
    })

    const onSubmit = () => {
      store.dispatch('app-group/addUserGroup', {
        api: 'http://api.bc-sport.ru/api/usergroups',
        // eslint-disable-next-line
        users: selectedPlayers.value.map(id => {
          return {
            user_id: id,
            group_id: props.group.id,
          }
        }),
      })
        .then(() => {
          emit('update:refresh', true)
          nextTick(() => {
            // reset refresh
            emit('update:refresh', false)
          })
          emit('update:is-add-player-sidebar-active', false)
        })
    }

    const resetForm = () => {
      emit('update:is-add-player-sidebar-active', false)
    }

    return {
      players,
      selectedPlayers,
      onSubmit,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-player-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
