<template>
  <b-card
    title="Игроки"
  >
    <b-row>
      <b-col
        sm="12"
      >
        <div class="d-flex justify-content-end mb-2">
          <b-button
            variant="primary"
            @click="isAddPlayerSidebarActive = true"
          >
            Добавить игрока
          </b-button>
        </div>
      </b-col>
      <b-col
        sm="12"
      >
        <basic-table
          :columns="columns"
          api="http://api.bc-sport.ru/api/usergroups"
          :refresh="refresh"
          :params="{
            group_id: $route.params.id,
          }"
        />
      </b-col>
    </b-row>
    <add-player-sidebar
      :is-add-player-sidebar-active.sync="isAddPlayerSidebarActive"
      :group="group"
      :refresh.sync="refresh"
    />
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import BasicTable from '@core/components/table/basic-table/BasicTable.vue'
import { ref } from '@vue/composition-api'
import AddPlayerSidebar from './AddPlayerSidebar.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BasicTable,
    AddPlayerSidebar,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    refresh: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const isAddPlayerSidebarActive = ref(false)

    const columns = [
      { key: 'player.profile.fio', sortable: false, label: 'ФИО' },
      { key: 'player.profile.city', sortable: false, label: 'Город' },
      { key: 'player.profile.dob', sortable: false, label: 'Дата рождения' },
      { key: 'player.profile.phone', sortable: false, label: 'Телефон' },
      { key: 'player.profile.parent_fio', sortable: false, label: 'Родитель' },
      { key: 'player.profile.parent_phone', sortable: false, label: 'Телефон родителя' },
      { key: 'player.profile.category', sortable: false, label: 'Возрастная категория' },
      { key: 'player.profile.email', sortable: false, label: 'Email' },
      {
        key: 'actions',
        label: '',
        sortable: false,
        actions: [
          {
            label: 'Удалить',
            icon: 'TrashIcon',
            api: 'http://api.bc-sport.ru/api/usergroups',
            method: 'delete',
          },
        ],
      },
    ]

    return {
      isAddPlayerSidebarActive,
      columns,
    }
  },
}
</script>
