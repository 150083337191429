import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'

export default function useSelectInput(props) {
  const opts = ref([])
  const { route } = useRouter()

  if (props.options) {
    opts.value = JSON.parse(JSON.stringify(props.options))
  }

  const fetchData = () => {
    let xparam1

    if (props.xparam1) {
      xparam1 = props.xparam1
    }

    const queryParams = {}

    if (props.queryParams && props.queryParams.length) {
      props.queryParams.forEach(p => {
        queryParams[p] = route.value.params[p]
      })
    }

    store.dispatch('select-input/fetchData', {
      ...props,
      params: {
        ...queryParams,
        xparam1,
      },
    })
      .then(response => {
        const { data } = response.data
        opts.value = data
      })
      .catch(() => {
        opts.value = []
      })
  }

  watch(() => props.apiId, () => {
    fetchData()
  })

  watch(() => props.xparam1, () => {
    fetchData()
  })

  watch([route], () => {
    fetchData()
  })

  return {
    opts,
    fetchData,
  }
}
