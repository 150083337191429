        <template>
  <b-card>
    <b-row
          >
                                    <b-col
                                                                                      sm="3"
                                            class="col-sm-3 pr-0"
                          >
                            <select-input
  id="hall_id"
  name="hall_id"
    :value.sync="hall_id"
        :options="[]"
      :getOptionLabel="option =&gt; option.name"
      :reduce="val =&gt; val.id"
            label="Зал"
  api="http://api.bc-sport.ru/api/halls"
    :queryParams="[&quot;partner_id&quot;,&quot;branche_id&quot;]"
  />            </b-col>
                                        <b-col
                                                                                      sm="9"
                                        >
              <div
                                class="d-flex justify-content-end mt-2"
                              >
                                  <div
                                        >
                                            <open-sidebar-button
  label="Добавить абонемент"
  :sidebar.sync="isSidebarActive"
    variant="primary"
      />                  </div>
                              </div>
            </b-col>
                                        <b-col
                                                                                                >
                            <create-update-sidebar
  title="Добавить абонемент"
  api="http://api.bc-sport.ru/api/subscriptions"
    :queryParams="[&quot;partner_id&quot;,&quot;branche_id&quot;]"
    :refresh.sync="refresh"
  :fields="[{&quot;name&quot;:&quot;hall_id&quot;,&quot;label&quot;:&quot;\u0417\u0430\u043b&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;v-select&quot;,&quot;value&quot;:&quot;&quot;,&quot;option&quot;:&quot;name&quot;,&quot;reduce&quot;:&quot;id&quot;,&quot;rules&quot;:&quot;required&quot;,&quot;options&quot;:[],&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/halls&quot;},{&quot;name&quot;:&quot;subscription_type&quot;,&quot;label&quot;:&quot;\u041d\u0430\u0437\u0432\u0430\u043d\u0438\u0435&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;text&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;},{&quot;name&quot;:&quot;count&quot;,&quot;label&quot;:&quot;\u041a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0437\u0430\u043d\u044f\u0442\u0438\u0439&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;number&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;},{&quot;name&quot;:&quot;price&quot;,&quot;label&quot;:&quot;\u0426\u0435\u043d\u0430&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;number&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;}]"
  :is-sidebar-active.sync="isSidebarActive"
/>            </b-col>
                                        <b-col
                                                                                                >
                            <update-sidebar
  title="Редактировать абонемент"
  api="http://api.bc-sport.ru/api/subscriptions"
  :apiId="editSidebarId"
  getApi="http://api.bc-sport.ru/api/subscriptions"
    :queryParams="[&quot;partner_id&quot;,&quot;branche_id&quot;]"
    :refresh.sync="refresh"
  :fields="[{&quot;name&quot;:&quot;hall_id&quot;,&quot;label&quot;:&quot;\u0417\u0430\u043b&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;v-select&quot;,&quot;option&quot;:&quot;name&quot;,&quot;reduce&quot;:&quot;id&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;,&quot;options&quot;:[],&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/halls&quot;},{&quot;name&quot;:&quot;subscription_type&quot;,&quot;label&quot;:&quot;\u041d\u0430\u0437\u0432\u0430\u043d\u0438\u0435&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;text&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;},{&quot;name&quot;:&quot;count&quot;,&quot;label&quot;:&quot;\u041a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0437\u0430\u043d\u044f\u0442\u0438\u0439&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;number&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;},{&quot;name&quot;:&quot;price&quot;,&quot;label&quot;:&quot;\u0426\u0435\u043d\u0430&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;number&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;}]"
  :is-sidebar-active.sync="editSidebarRef"
/>            </b-col>
                                        <b-col
                                                                                      sm="12"
                                        >
                            <basic-table
  :columns="[{&quot;key&quot;:&quot;hall.name&quot;,&quot;label&quot;:&quot;\u0417\u0430\u043b&quot;,&quot;sortable&quot;:true,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;subscription_type&quot;,&quot;label&quot;:&quot;\u0412\u0438\u0434 \u0430\u0431\u043e\u043d\u0435\u043c\u0435\u043d\u0442\u0430&quot;,&quot;sortable&quot;:true,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;price&quot;,&quot;label&quot;:&quot;\u0426\u0435\u043d\u0430&quot;,&quot;sortable&quot;:true,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;actions&quot;,&quot;label&quot;:&quot;&quot;,&quot;sortable&quot;:false,&quot;tdClass&quot;:&quot;text-right&quot;,&quot;actions&quot;:[{&quot;label&quot;:&quot;\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c&quot;,&quot;name&quot;:&quot;Edit&quot;,&quot;icon&quot;:&quot;EditIcon&quot;,&quot;alert&quot;:false},{&quot;label&quot;:&quot;\u0423\u0434\u0430\u043b\u0438\u0442\u044c&quot;,&quot;icon&quot;:&quot;TrashIcon&quot;,&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/subscriptions&quot;,&quot;method&quot;:&quot;delete&quot;}]}]"
      :queryParams="[&quot;partner_id&quot;,&quot;branche_id&quot;]"
        :refresh="refresh"
      :editSidebarRef.sync="editSidebarRef"
                        :brancheId="branche_id"
          :hallId="hall_id"
        :editSidebarId.sync="editSidebarId"
    api="http://api.bc-sport.ru/api/subscriptions"
          />            </b-col>
                            </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'

                                      import SelectInput from '@core/components/input/select-input/SelectInput.vue'
                                                                            import OpenSidebarButton from '@core/components/button/OpenSidebarButton.vue'
                                                                          import CreateUpdateSidebar from '@core/components/sidebar/CreateUpdateSidebar.vue'
                                                              import UpdateSidebar from '@core/components/sidebar/UpdateSidebar.vue'
                                                              import BasicTable from '@core/components/table/basic-table/BasicTable.vue'
                            
export default {
  components: {
    BCard,
    BRow,
    BCol,

                                                          SelectInput,
                                                                                              OpenSidebarButton,
                                                                                            CreateUpdateSidebar,
                                                                              UpdateSidebar,
                                                                              BasicTable,
                                          },
  setup() {
                const isSidebarActive = ref(false)
            const editSidebarRef = ref(false)
            const editSidebarId = ref(null)
            const branche_id = ref(undefined)
            const hall_id = ref(undefined)
            const refresh = ref(false)
          
          return {
            isSidebarActive,
            editSidebarRef,
            editSidebarId,
            branche_id,
            hall_id,
            refresh,
            }
      },
}
</script>
    