<template>
  <b-card>

    <b-row>
      <!-- Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ groupData.name }}
              </h4>
            </div>
          </div>
        </div>

        <!-- Stats -->
        <div
          class="d-flex align-items-center mt-2"
        >
          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="UsersIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ groupData.players.length }}
              </h5>
              <small>Игроков в группе</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
      <table class="mt-2 mt-xl-0 w-100">
          <tr v-if="groupData.trainer">
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Тренер</span>
            </th>
            <td>
              {{ groupData.trainer.profile.fio }}
            </td>
          </tr>
          <tr v-if="groupData.category">
            <th class="pb-50">
              <feather-icon
                icon="SmileIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Возрастная категория</span>
            </th>
            <td>
              <b-badge :variant="groupData.category.color">{{ groupData.category.name }}</b-badge>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BBadge,
  },
  props: {
    groupData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {}
  },
}
</script>

<style>

</style>
