<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar && `${server}${userData.avatar}`"
          :text="avatarText(userData.fio)"
          size="90px"
          rounded
        />
      </template>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline" v-if="userData.avatar">Обновить</span>
          <span class="d-none d-sm-inline" v-else>Загрузить</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
          @click="userData.avatar = null"
        >
          <span class="d-none d-sm-inline">Удалить</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>

        <!-- Field: FIO -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="ФИО"
            label-for="fio"
          >
            <b-form-input
              id="fio"
              v-model="userData.fio"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Город"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="userData.city"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Birth Date -->
        <b-col
          v-if="roleSlug !== 'superadmin' && roleSlug !== 'partner'"
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Дата рождения"
            label-for="birth-date"
          >
            <date-picker
              id="birth-date"
              v-model="userData.dob"
              class="w-100"
              format="DD.MM.YYYY"
              placeholder="дд.мм.гггг"
            />
          </b-form-group>
        </b-col>

        <!-- Field: phone -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Телефон"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="userData.phone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: parent_fio -->
        <b-col
          v-if="roleSlug === 'player'"
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Родитель"
            label-for="parent_fio"
          >
            <b-form-input
              id="parent"
              v-model="userData.parent_fio"
            />
          </b-form-group>
        </b-col>

        <!-- Field: parent_phone -->
        <b-col
          v-if="roleSlug === 'player'"
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Телефон родителя"
            label-for="parent_phone"
          >
            <b-form-input
              id="parent_phone"
              v-model="userData.parent_phone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: parent_phone -->
        <b-col
          v-if="roleSlug === 'player' || roleSlug === 'trainer'"
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Возрастная категория"
            label-for="category_id"
          >
            <v-select
              v-model="userData.category_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categories"
              :reduce="val => val.id"
              :get-option-label="option => option.name"
              :clearable="false"
              input-id="category_id"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateUser()"
            :disabled="loading"
          >
            <b-spinner
              v-if="loading"
              small
              class="mr-50"
            />
            <span>Сохранить изменения</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BMedia, BAvatar, BForm,
  BFormGroup, BFormInput, BButton,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BSpinner,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    userRoles: {
      type: Array,
      required: true,
    },
    api: {
      type: String,
      required: true,
    },
  },
  setup(props, { root }) {
    const server = process.env.VUE_APP_SERVER

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, () => {
      store.dispatch('app/uploadImage', {
        image: refInputEl.value.files[0],
      }).then(res => {
        if (res.image) {
          // eslint-disable-next-line no-param-reassign
          props.userData.avatar = `storage/${res.image}`
        }
      })
    })

    const categories = ref([])

    store.dispatch('app-user/fetchAgeCategories')
      .then(response => {
        categories.value = response.data.data
      })
      .catch(() => {
        categories.value = []
      })

    const loading = ref(false)
    const updateUser = () => {
      loading.value = true
      store.dispatch('app-user/updateUser', {
        api: props.api,
        id: props.userData.user_id,
        userData: {
          avatar: props.userData.avatar,
          category_id: props.userData.category_id,
          city: props.userData.city,
          dob: props.userData.dob,
          email: props.userData.email,
          fio: props.userData.fio,
          parent_fio: props.userData.parent_fio,
          parent_phone: props.userData.parent_phone,
          phone: props.userData.phone,
        },
      })
        .then(() => {
          loading.value = false

          root.$toast({
            component: ToastificationContent,
            props: {
              title: '',
              text: 'Профиль обновлен',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err)
          loading.value = false
        })
    }

    const roleSlug = props.userRoles[0].slug

    return {
      server,
      avatarText,
      refInputEl,
      previewEl,
      inputImageRenderer,
      categories,
      loading,
      updateUser,
      roleSlug,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
