import '@fullcalendar/core/vdom'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'

import { useRouter } from '@core/utils/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'

export default function useEvents(props) {
  const { route } = useRouter()

  const toast = useToast()
  const refCalendar = ref(null)
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  const blankEvent = {}
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  const removeEventInCalendar = eventId => {
    calendarApi.getEventById(eventId).remove()
  }

  const grabEventDataFromEventApi = eventApi => {
    const {
      /* eslint-disable camelcase */
      id,
      title,
      start: date_start,
      end: date_end,
      extendedProps: {
        branche_id,
        hall_id,
        trainer_id,
        admin_id,
        category_id,
        group_id,
        players,
        category,
        admin,
        trainer,
        group,
        hall,
        visited,
      },
      /* eslint-disable camelcase */
    } = eventApi

    return {
      /* eslint-disable camelcase */
      id,
      title,
      date_start,
      date_end,
      branche_id,
      hall_id,
      trainer_id,
      admin_id,
      category_id,
      group_id,
      players,
      category,
      admin,
      trainer,
      group,
      hall,
      visited,
      /* eslint-disable camelcase */
    }
  }

  const addEvent = eventData => {
    store.dispatch('app-events/addEvent', {
      ...eventData,
      date_end: new Date(eventData.date_end).toUTCString(),
      date_start: new Date(eventData.date_start).toUTCString(),
    }).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    })
  }

  const removeEvent = id => {
    const eventId = id || event.value.id
    store.dispatch('app-events/removeEvent', { id: eventId }).then(res => {
      if (res) {
        removeEventInCalendar(eventId)
      }
    })
  }

  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  const updateEvent = eventData => {
    store.dispatch('app-events/updateEvent', {
      id: eventData.id,
      event: {
        ...eventData,
        date_end: new Date(eventData.date_end).toUTCString(),
        date_start: new Date(eventData.date_start).toUTCString(),
      },
    }).then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Событие обновлено',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      refetchEvents()
    })
  }

  const selectedCalendars = computed(() => store.state['app-events'].selectedCalendars)

  watch(selectedCalendars, () => {
    refetchEvents()
  })

  watch([route], () => {
    refetchEvents()
  })

  const fetchEvents = (info, successCallback) => {
    if (!info) return

    const queryParams = {}

    if (props.queryParams && props.queryParams.length) {
      props.queryParams.forEach(p => {
        queryParams[p] = route.value.params[p]
      })
    }

    store
      .dispatch('app-events/fetchEvents', {
        params: {
          ...queryParams,
        },
      })
      .then(response => {
        successCallback(response.data.data.map(item => ({
          id: item.id,
          title: item.title,
          start: item.date_start,
          end: item.date_end,
          branche_id: item.branche_id,
          hall_id: item.hall_id,
          trainer_id: item.trainer_id,
          admin_id: item.admin_id,
          category_id: item.category_id,
          group_id: item.group_id,
          category: item.category,
          admin: item.admin,
          trainer: item.trainer,
          group: item.group,
          hall: item.hall,
          visited: item.visited,
        })))
      })
  }

  const eventClickShow = evt => {
    event.value = grabEventDataFromEventApi(evt)

    // eslint-disable-next-line no-use-before-define
    isEventHandlerModalActive.value = true
  }

  const eventClickEdit = evt => {
    event.value = grabEventDataFromEventApi(evt)

    // eslint-disable-next-line no-use-before-define
    isEventHandlerSidebarActive.value = true
  }

  const calendarOptions = ref({
    timeZone: 'local',
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'listMonth',
    slotMinTime: '09:00:00',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    events: fetchEvents,
    locales: allLocales,
    locale: 'ru',

    editable: true,

    eventResizableFromStart: true,

    dragScroll: true,

    dayMaxEvents: 2,

    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarEvent._def.extendedProps.category && calendarEvent._def.extendedProps.category.color

      return [
        `bg-light-${colorName}`,
      ]
    },
    // eventClick({ event: clickedEvent, jsEvent: ev }) {
    //   ev.preventDefault()
    //   event.value = grabEventDataFromEventApi(clickedEvent)

    //   // eslint-disable-next-line no-use-before-define
    //   isEventHandlerSidebarActive.value = true
    // },

    customButtons: {
      sidebarToggle: {
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },

    dateClick(info) {
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { date_start: info.date })))
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)
  const isEventHandlerModalActive = ref(false)

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    isEventHandlerModalActive,
    calendarOptions,
    event,
    eventClickShow,
    eventClickEdit,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,

    isEventHandlerSidebarActive,
  }
}
