import { ref, watch, computed } from '@vue/composition-api'
import { debounce } from 'lodash'
import { useRouter } from '@core/utils/utils'
import moment from 'moment'
import store from '@/store'

export default function useCalendarEventHandler(props, clearForm, refetchEvents, emit) {
  const { route } = useRouter()
  const eventData = ref(JSON.parse(JSON.stringify(props.event.value)))
  const resetEventData = () => {
    eventData.value = JSON.parse(JSON.stringify(props.event.value))
  }

  const startDateError = ref(false)
  const endDateError = ref(false)

  const onSubmit = () => {
    if (!startDateError.value && !endDateError.value) {
      if (props.event.value.id) {
        emit('update-event', eventData.value)
      } else {
        emit('add-event', {
          ...eventData.value,
          branche_id: route.value.params.branche_id,
        })
      }

      // Close sidebar
      emit('update:is-event-handler-sidebar-active', false)
    }
  }

  /* eslint-enable global-require */

  const halls = ref([])
  const trainers = ref([])
  const admins = ref([])
  const categories = ref([])
  const groups = ref([])
  const players = ref([])

  const loading = ref(false)
  const loading2 = ref(false)
  const refUserListTable = ref(null)
  const tableColumns = [
    { key: 'player.profile.fio', sortable: false, label: 'ФИО' },
    { key: 'player.profile.city', sortable: false, label: 'Город' },
    { key: 'player.profile.dob', sortable: false, label: 'Дата рождения' },
    { key: 'player.profile.phone', sortable: false, label: 'Телефон' },
    { key: 'player.profile.parent_fio', sortable: false, label: 'Родитель' },
    { key: 'player.profile.parent_phone', sortable: false, label: 'Телефон родителя' },
    { key: 'player.profile.category', sortable: false, label: 'Возрастная категория' },
    { key: 'player.profile.email', sortable: false, label: 'Email' },
    { key: 'actions', label: '', sortable: false },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const selectedItems = ref([])
  const toggle = item => {
    loading.value = true
    if (item.selected) {
      selectedItems.value.push({
        ...item,
      })

      store
        .dispatch('app-events/addEventVisitedUser', {
          event_id: props.event.value.id,
          user_id: item.user_id,
          visited_at: new Date(props.event.value.date_start).toUTCString(),
        })
        .then(() => {
          loading.value = false
          refetchEvents()
        })
        .catch(() => {
          loading.value = false
        })
    } else {
      const index = selectedItems.value.findIndex(elem => elem.id === item.id)
      if (index > -1) {
        store
          .dispatch('app-events/removeEventVisitedUser', {
            event_id: props.event.value.id,
            user_id: item.user_id,
          })
          .then(() => {
            loading.value = false
            selectedItems.value.splice(index, 1)
            refetchEvents()
          })
          .catch(() => {
            loading.value = false
          })
      }
    }
  }

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  const asyncFetchUsers = (ctx, callback) => {
    loading.value = true
    store
      .dispatch('app-events/fetchGroupPlayers', {
        params: {
          group_id: props.event.value.group_id,
        },
      })
      .then(response => {
        const { data, meta } = response.data

        const visited = []
        if (data && data.length && props.event.value.visited && props.event.value.visited.length) {
          props.event.value.visited.forEach(v => {
            // eslint-disable-next-line
            const user = data.filter(u => {
              if (u.user_id === v.user_id) {
                // eslint-disable-next-line
                u.selected = true

                return u
              }
            })[0]

            if (user) {
              visited.push({
                ...user,
                selected: true,
              })
            }
          })

          selectedItems.value = visited
        }

        callback(data)
        loading.value = false
        totalUsers.value = meta.total
      })
      .catch(() => {
        loading.value = false
      })
  }

  const fetchUsers = debounce((ctx, callback) => asyncFetchUsers(ctx, callback), 100)

  const fetchHalls = () => {
    store
      .dispatch('app-events/fetchHalls', {
        params: {
          branche_id: route.value.params.branche_id,
        },
      })
      .then(response => {
        halls.value = response.data.data
      })
  }

  const fetchAdmins = () => {
    store
      .dispatch('app-events/fetchAdmins', {
        params: {
          branche_id: route.value.params.branche_id,
        },
      })
      .then(response => {
        admins.value = response.data.data
      })
  }

  const fetchCategories = () => {
    store
      .dispatch('app-events/fetchCategories', {
        params: {
          branche_id: route.value.params.branche_id,
        },
      })
      .then(response => {
        categories.value = response.data.data
      })
  }

  const fetchTrainers = () => {
    store
      .dispatch('app-events/fetchTrainers', {
        params: {
          branche_id: route.value.params.branche_id,
          category_ids: eventData.value.category_id,
        },
      })
      .then(response => {
        trainers.value = response.data.data
      })
  }

  const fetchPlayers = () => {
    store
      .dispatch('app-events/fetchPlayers', {
        params: {
          branche_id: route.value.params.branche_id,
          category_id: eventData.value.category_id,
        },
      })
      .then(response => {
        players.value = response.data.data
      })
  }

  const selectGroup = val => {
    if (val.id) {
      players.value = val.players || []
      eventData.value.players = val.players
      eventData.value.group_id = val.id
    } else {
      const group = groups.value.filter(item => item.id === eventData.value.group_id)[0]
      if (group) {
        players.value = group.players || []
        eventData.value.players = group.players
        eventData.value.group_id = group.id
      }
    }

    // fetchPlayers()
  }

  const fetchGroups = () => {
    store
      .dispatch('app-events/fetchGroups', {
        params: {
          branche_id: route.value.params.branche_id,
          category_id: eventData.value.category_id,
          user_id: eventData.value.trainer_id,
        },
      })
      .then(response => {
        if (!eventData.value.group_id) {
          groups.value = response.data.data
        } else {
          groups.value = response.data.data
          const group = groups.value.filter(item => item.id === eventData.value.group_id)[0]
          if (group) {
            selectGroup(group)
          }
        }
      })
  }

  const selectCategory = () => {
    fetchGroups()
    fetchTrainers()
  }

  const inputDateStart = val => {
    if (!val || val.length === 0) {
      startDateError.value = true
    } else {
      eventData.value.date_end = moment(val).add(1, 'hours').toISOString()
      startDateError.value = false
      endDateError.value = false
    }
  }

  const inputDateEnd = val => {
    if (!val || val.length === 0) {
      endDateError.value = true
    } else {
      endDateError.value = false
    }
  }

  watch(props.event, () => {
    resetEventData()
  })

  watch(props.isEventHandlerSidebarActive, val => {
    if (!val) {
      setTimeout(() => {
        clearForm.value()
        startDateError.value = false
        endDateError.value = false
      }, 350)
    }

    if (props.isEventHandlerSidebarActive.value) {
      if (!eventData.value.id) {
        fetchHalls()
        fetchAdmins()
        fetchCategories()
      } else {
        fetchHalls()
        fetchAdmins()
        fetchCategories()
        fetchGroups()
        fetchTrainers()
        startDateError.value = false
        endDateError.value = false
      }
    }
  })

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  return {
    eventData,
    resetEventData,

    halls,
    trainers,
    categories,
    groups,
    admins,
    players,
    fetchHalls,
    fetchTrainers,
    fetchCategories,
    fetchGroups,
    fetchAdmins,
    fetchPlayers,

    selectCategory,
    selectGroup,

    startDateError,
    endDateError,
    onSubmit,

    fetchUsers,
    refUserListTable,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,

    selectedItems,
    toggle,
    loading,
    loading2,
    refetchData,
    inputDateStart,
    inputDateEnd,
  }
}
