/* eslint-disable */
// prettier-ignore

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-login',
      name: 'verify-login',
      component: () => import('@/views/pages/VerifyLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-login/:id',
      name: 'verify-login',
      component: () => import('@/views/pages/VerifyLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/pages/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/pages/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/pages/profile/Profile.vue'),
      meta: {
        pageTitle: 'Профиль',
      },
    },
    
    
        {
      path: '/',
      name: 'main',
      component: () => import('@/views/apps/main/Main.vue'),
          },
        {
      path: '/branchs',
      name: 'branchs',
      component: () => import('@/views/apps/branchs/Branchs.vue'),
            meta: {"pageTitle":"\u0424\u0438\u043b\u0438\u0430\u043b\u044b","breadcrumb":[{"text":"\u0421\u043f\u0438\u0441\u043e\u043a \u0444\u0438\u043b\u0438\u0430\u043b\u043e\u0432","active":true}]},
          },
        {
      path: '/:branche_id',
      name: 'branche',
      component: () => import('@/views/apps/main/Main.vue'),
          },
        {
      path: '/:branche_id/calendar',
      name: 'calendar',
      component: () => import('@/views/apps/events/Events.vue'),
            meta: {"pageTitle":"\u041a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c","breadcrumb":[{"text":"\u041a\u0430\u043b\u0435\u043d\u0434\u0430\u0440\u044c","active":true}]},
          },
        {
      path: '/:branche_id/players',
      name: 'players',
      component: () => import('@/views/apps/players/Players.vue'),
            meta: {"pageTitle":"\u0418\u0433\u0440\u043e\u043a\u0438","breadcrumb":[{"text":"\u0421\u043f\u0438\u0441\u043e\u043a \u0438\u0433\u0440\u043e\u043a\u043e\u0432","active":true}]},
          },
        {
      path: '/:branche_id/players/:id/view',
      name: 'players-view',
      component: () => import('@/views/apps/players-view/PlayersView.vue'),
            meta: {"pageTitle":"\u0418\u0433\u0440\u043e\u043a","breadcrumb":[{"text":"\u0418\u0433\u0440\u043e\u043a\u0438","active":false,"to":{"name":"players"}},{"text":"\u041a\u0430\u0440\u0442\u043e\u0447\u043a\u0430 \u0438\u0433\u0440\u043e\u043a\u0430","active":true}]},
          },
        {
      path: '/:branche_id/players/:id/edit',
      name: 'players-edit',
      component: () => import('@/views/apps/players-edit/PlayersEdit.vue'),
            meta: {"pageTitle":"\u0418\u0433\u0440\u043e\u043a","breadcrumb":[{"text":"\u0418\u0433\u0440\u043e\u043a\u0438","active":false,"to":{"name":"players"}},{"text":"\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0438\u0433\u0440\u043e\u043a\u0430","active":true}]},
          },
        {
      path: '/:branche_id/trainers',
      name: 'trainers',
      component: () => import('@/views/apps/trainers/Trainers.vue'),
            meta: {"pageTitle":"\u0422\u0440\u0435\u043d\u0435\u0440\u0430","breadcrumb":[{"text":"\u0421\u043f\u0438\u0441\u043e\u043a \u0442\u0440\u0435\u043d\u0435\u0440\u043e\u0432","active":true}]},
          },
        {
      path: '/:branche_id/trainers/:id/view',
      name: 'trainers-view',
      component: () => import('@/views/apps/trainers-view/TrainersView.vue'),
            meta: {"pageTitle":"\u0422\u0440\u0435\u043d\u0435\u0440","breadcrumb":[{"text":"\u0422\u0440\u0435\u043d\u0435\u0440\u044b","active":false,"to":{"name":"trainers"}},{"text":"\u041a\u0430\u0440\u0442\u043e\u0447\u043a\u0430 \u0442\u0440\u0435\u043d\u0435\u0440\u0430","active":true}]},
          },
        {
      path: '/:branche_id/trainers/:id/edit',
      name: 'trainers-edit',
      component: () => import('@/views/apps/trainers-edit/TrainersEdit.vue'),
            meta: {"pageTitle":"\u0422\u0440\u0435\u043d\u0435\u0440","breadcrumb":[{"text":"\u0422\u0440\u0435\u043d\u0435\u0440\u044b","active":false,"to":{"name":"trainers"}},{"text":"\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0442\u0440\u0435\u043d\u0435\u0440\u0430","active":true}]},
          },
        {
      path: '/:branche_id/admins',
      name: 'admins',
      component: () => import('@/views/apps/admins/Admins.vue'),
            meta: {"pageTitle":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u044b","breadcrumb":[{"text":"\u0421\u043f\u0438\u0441\u043e\u043a \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u043e\u0432","active":true}]},
          },
        {
      path: '/:branche_id/admins/:id/view',
      name: 'admins-view',
      component: () => import('@/views/apps/admins-view/AdminsView.vue'),
            meta: {"pageTitle":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440","breadcrumb":[{"text":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u044b","active":false,"to":{"name":"admins"}},{"text":"\u041a\u0430\u0440\u0442\u043e\u0447\u043a\u0430 \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u0430","active":true}]},
          },
        {
      path: '/:branche_id/admins/:id/edit',
      name: 'admins-edit',
      component: () => import('@/views/apps/admins-edit/AdminsEdit.vue'),
            meta: {"pageTitle":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440","breadcrumb":[{"text":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u044b","active":false,"to":{"name":"admins"}},{"text":"\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u0430","active":true}]},
          },
        {
      path: '/:branche_id/groups',
      name: 'groups',
      component: () => import('@/views/apps/groups/Groups.vue'),
            meta: {"pageTitle":"\u0413\u0440\u0443\u043f\u043f\u044b","breadcrumb":[{"text":"\u0421\u043f\u0438\u0441\u043e\u043a \u0433\u0440\u0443\u043f\u043f","active":true}]},
          },
        {
      path: '/:branche_id/groups/:id/view',
      name: 'groups-view',
      component: () => import('@/views/apps/groups-view/GroupsView.vue'),
            meta: {"pageTitle":"\u0413\u0440\u0443\u043f\u043f\u0430","breadcrumb":[{"text":"\u0413\u0440\u0443\u043f\u043f\u044b","active":false,"to":{"name":"groups"}},{"text":"\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435 \u0433\u0440\u0443\u043f\u043f\u044b","active":true}]},
          },
        {
      path: '/:branche_id/finances',
      name: 'finances',
      component: () => import('@/views/apps/finances/Finances.vue'),
            meta: {"pageTitle":"\u0424\u0438\u043d\u0430\u043d\u0441\u044b","breadcrumb":[{"text":"\u0424\u0438\u043d\u0430\u043d\u0441\u044b","active":true}]},
          },
        {
      path: '/:branche_id/halls',
      name: 'halls',
      component: () => import('@/views/apps/halls/Halls.vue'),
            meta: {"pageTitle":"\u0417\u0430\u043b\u044b","breadcrumb":[{"text":"\u0421\u043f\u0438\u0441\u043e\u043a \u0437\u0430\u043b\u043e\u0432","active":true}]},
          },
        {
      path: '/:branche_id/agecategorys',
      name: 'agecategorys',
      component: () => import('@/views/apps/agecategorys/Agecategorys.vue'),
            meta: {"pageTitle":"\u0412\u043e\u0437\u0440\u0430\u0441\u0442\u043d\u044b\u0435 \u043a\u0430\u0442\u0435\u0433\u043e\u0440\u0438\u0438","breadcrumb":[{"text":"\u0421\u043f\u0438\u0441\u043e\u043a \u0432\u043e\u0437\u0440\u0430\u0441\u0442\u043d\u044b\u0445 \u043a\u0430\u0442\u0435\u0433\u043e\u0440\u0438\u0439","active":true}]},
          },
        {
      path: '/:branche_id/subscriptions',
      name: 'subscriptions',
      component: () => import('@/views/apps/subscriptions/Subscriptions.vue'),
            meta: {"pageTitle":"\u0410\u0431\u043e\u043d\u0435\u043c\u0435\u043d\u0442\u044b","breadcrumb":[{"text":"\u0421\u043f\u0438\u0441\u043e\u043a \u0430\u0431\u043e\u043d\u0435\u043c\u0435\u043d\u0442\u043e\u0432","active":true}]},
          },
        {
      path: '/attestations',
      name: 'attestations',
      component: () => import('@/views/apps/attestations/Attestations.vue'),
            meta: {"pageTitle":"\u0410\u0442\u0442\u0435\u0441\u0442\u0430\u0446\u0438\u0438","breadcrumb":[{"text":"\u0410\u0442\u0442\u0435\u0441\u0442\u0430\u0446\u0438\u0438","active":true}]},
          },
        {
      path: '/(\\d+)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/(\[a-zA-Z]{1,}[0-9]{1,}\)',
      name: 'refsignup',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

if (localStorage.getItem('userData')) {
  
  
}

router.beforeEach(async (to, from, next) => {
  if (!store.getters['app/isLoggedIn'] && localStorage.getItem('accessToken') && localStorage.getItem('userData')) {
    store.commit('app/SET_USER', JSON.parse(localStorage.getItem('userData')))
    store.commit('app/SET_LOGGED_IN', true)
  }

  if (!localStorage.getItem('accessToken') && to.matched[0] && to.matched[0].path !== '/login') {
    if (to.matched[0].path === '/signup') {
      next()
    } else if (to.matched[0].path === '/verify-login/:id') {
      next()
    }
    else if (to.matched[0].path === '/verify-login') {
      next()
    }
    else if (to.matched[0].path === '/(\\d+)') {
      next()
    }
    else if (to.matched[0].path === '/(\[a-zA-Z]{1,}[0-9]{1,}\)') {
      next()
    }
    else if (to.matched[0].path === '/reset-password') {
      next()
    } else if (to.matched[0].path === '/confidence') {
      next()
    }
    else if (to.matched[0].path === '/confidenceBinar') {
      next()
    }
    else if (to.matched[0].path === '/forgot-password') {
      next()
    } else if (to.matched[0].path === '/report-form/:id') {
      next()
    } else if (to.matched[0].path === '/report-form/:id/:channel') {
      next()
    } else {
      next({
        path: '/login',
      })
    }
  }

  if (localStorage.getItem('accessToken') && to.matched[0] && to.matched[0].path === '/login') {
    next({
      path: '/',
    })
  }

  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
