import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, { api, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(api, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { api, id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${api}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, { api, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(api, { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, { api, id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${api}/${id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgeCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/agecategorys', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/roles', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
