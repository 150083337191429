/* eslint-disable */
if (!localStorage.getItem('search-and-bookmark-data')) {
  localStorage.setItem('search-and-bookmark-data', JSON.stringify({
    pages: {
      key: 'title',
      data: [
                {
          title: 'Main',
                    route: 'branche',
                              icon: 'HomeIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Пользователи',
                    route: 'users',
                              icon: 'UserIcon',
                            },
                {
          title: 'Залы',
                    route: 'halls',
                              icon: 'TabletIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Группы',
                    route: 'groups',
                              icon: 'UsersIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Календарь',
                    route: 'calendar',
                              icon: 'CalendarIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Финансы',
                    route: 'finances',
                              icon: 'DollarSignIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Абонементы',
                    route: 'subscriptions',
                              icon: 'CreditCardIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Справочники',
                              icon: 'BookIcon',
                            },
              ]
    },
  }))
}
// prettier-ignore
export default [
    {
    title: 'Main',
        route: 'branche',
            icon: 'HomeIcon',
          },
    {
    title: 'Пользователи',
        route: 'users',
            icon: 'UserIcon',
            children: [{"title":"\u0418\u0433\u0440\u043e\u043a\u0438","route":"players","icon":"CircleIcon","isBookmarked":false},{"title":"\u0422\u0440\u0435\u043d\u0435\u0440\u0430","route":"trainers","icon":"CircleIcon","isBookmarked":false},{"title":"\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u044b","route":"admins","icon":"CircleIcon","isBookmarked":false}],
      },
    {
    title: 'Залы',
        route: 'halls',
            icon: 'TabletIcon',
          },
    {
    title: 'Группы',
        route: 'groups',
            icon: 'UsersIcon',
          },
    {
    title: 'Календарь',
        route: 'calendar',
            icon: 'CalendarIcon',
          },
    {
    title: 'Финансы',
        route: 'finances',
            icon: 'DollarSignIcon',
          },
    {
    title: 'Абонементы',
        route: 'subscriptions',
            icon: 'CreditCardIcon',
          },
    {
    title: 'Справочники',
            icon: 'BookIcon',
            children: [{"title":"\u0412\u043e\u0437\u0440\u0430\u0441\u0442\u043d\u044b\u0435 \u043a\u0430\u0442\u0435\u0433\u043e\u0440\u0438\u0438","route":"agecategorys","icon":"CircleIcon","isBookmarked":false}],
      },
  ]
