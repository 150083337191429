import {
  ref, computed, watch, set,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'

export default function useBasicTable(props, emit) {
  const refBasicTable = ref(null)
  const gridData = ref(null)
  const loading = ref(false)
  const total = ref(0)
  const currentPage = ref(1)
  const perPage = ref(25)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = gridData.value ? gridData.value.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })

  const { route, router } = useRouter()

  const fetchData = () => {
    loading.value = true
    // eslint-disable-next-line prefer-destructuring
    const brancheId = props.brancheId
    // eslint-disable-next-line prefer-destructuring
    const userId = props.userId
    // eslint-disable-next-line prefer-destructuring
    const categoryId = props.categoryId
    // eslint-disable-next-line prefer-destructuring
    const hallId = props.hallId
    // eslint-disable-next-line
    const id_buyer = props.id_buyer
    // eslint-disable-next-line
    const q = props.q

    const queryParams = {}

    if (props.queryParams && props.queryParams.length) {
      props.queryParams.forEach(p => {
        queryParams[p] = route.value.params[p]
      })
    }

    return store.dispatch('basic-table/fetchData', {
      api: props.api,
      apiId: props.apiId,
      params: {
        branche_id: brancheId,
        user_id: userId,
        category_id: categoryId,
        hall_id: hallId,
        q,
        ...queryParams,
        // eslint-disable-next-line
        id_buyer,
        sortby: sortBy.value,
        order: isSortDirDesc.value ? 'desc' : 'asc',
        page: currentPage.value,
        per_page: perPage.value,
        ...props.params,
      },
    })
      .then(response => {
        const { data, meta } = response.data
        gridData.value = data
        total.value = meta.total || 0
        loading.value = false
        return response
      })
      .catch(err => {
        gridData.value = null
        total.value = 0
        loading.value = false
        return err
      })
  }

  const rowClicked = item => {
    // eslint-disable-next-line no-underscore-dangle
    if (!item._showDetails) {
      // eslint-disable-next-line no-underscore-dangle
      set(item, '_showDetails', !item._showDetails)
    } else {
      // eslint-disable-next-line no-underscore-dangle
      set(item, '_showDetails', !item._showDetails)
    }
  }

  const rowClick = val => {
    if (props.isRowClicked) {
      router.push({
        name: `${route.value.name}-view`,
        params: { id: val.id },
      })
    }
  }

  const execAction = action => {
    if (action.name !== 'Edit') {
      store.dispatch('basic-table/execAction', {
        ...action,
        alert: action.alert === undefined || false,
      }).then(res => {
        if (res) {
          fetchData()
        }
      })
    } else {
      emit('update:editSidebarRef', true)
      emit('update:editSidebarId', action.apiId)
    }
  }

  watch(() => props.apiId, () => {
    if (props.loadByTab === null || (props.currentTab === props.loadByTab)) {
      fetchData()
    }
  })

  watch(() => props.brancheId, () => {
    fetchData()
  })

  watch(() => props.userId, () => {
    fetchData()
  })

  watch(() => props.categoryId, () => {
    fetchData()
  })

  watch(() => props.hallId, () => {
    fetchData()
  })

  watch(() => props.q, () => {
    if (props.q.length >= 3) {
      fetchData()
    }

    if (!props.q.length) {
      fetchData()
    }
  })

  watch([route], () => {
    fetchData()
  })

  // eslint-disable-next-line
  watch(() => props.id_buyer, () => {
    if (props.loadByTab === null || (props.currentTab === props.loadByTab)) {
      fetchData()
    }
  })

  watch(() => props.currentTab, () => {
    if (props.currentTab === props.loadByTab) {
      fetchData()
    }
  })

  watch([currentPage, sortBy, isSortDirDesc], () => {
    fetchData()
  })

  watch(() => props.refresh, () => {
    if (props.refresh) {
      fetchData()
    }
  })

  return {
    refBasicTable,
    gridData,
    loading,
    total,
    currentPage,
    perPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    dataMeta,

    fetchData,
    rowClicked,
    rowClick,
    execAction,
  }
}
