<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
        />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            >
            <template v-slot:eventContent='arg'>
              <div>
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  class="tree-dropdown-menu"
                >
                  <template #button-content>
                    <div v-if="arg.view.type === 'listMonth'" class="text-left">
                      <div>
                        <strong>{{ arg.event.title }}</strong>
                      </div>
                      <div>
                        Зал: {{ arg.event.extendedProps.hall && arg.event.extendedProps.hall.name }}
                      </div>
                      <div>
                        Группа: {{ arg.event.extendedProps.group && arg.event.extendedProps.group.name }}
                      </div>
                      <div>
                        Администратор: {{ arg.event.extendedProps.admin && arg.event.extendedProps.admin.profile.fio }}
                      </div>
                      <div>
                        Тренер: {{ arg.event.extendedProps.trainer && arg.event.extendedProps.trainer.profile.fio }}
                      </div>
                      <div>
                        Игроки всего/пришло: {{ arg.event.extendedProps.group.players.length }}/{{ arg.event.extendedProps.visited.length }}
                      </div>
                    </div>
                    <div v-else>
                      <strong>{{ arg.event.title }}</strong>
                    </div>
                  </template>
                  <b-dropdown-item
                    @click="eventClickShow(arg.event)"
                  >
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Открыть</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="eventClickEdit(arg.event)"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Редактировать</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="removeEvent(arg.event.id)"
                  >
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Удалить</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            </full-calendar>
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
        :is-event-handler-modal-active.sync="isEventHandlerModalActive"
        :event="event"
        :clear-event-data="clearEventData"
        :refetch-events="refetchEvents"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
    </div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import eventsStoreModule from './eventsStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useEvents from './useEvents'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
  },
  props: {
    queryParams: {
      type: Array,
      required: false,
    },
  },
  setup(props) {
    const APP_STORE_MODULE_NAME = 'app-events'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, eventsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      isEventHandlerModalActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      eventClickShow,
      eventClickEdit,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useEvents(props)

    fetchEvents()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      isEventHandlerModalActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      eventClickShow,
      eventClickEdit,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
