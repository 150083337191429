<template>
  <div>
    <b-sidebar
      id="sidebar-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ eventData.id ? $t('Update Event') : $t('Add Event') }}
          </h5>
          <div>
            <feather-icon
              v-if="eventData.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="() => {
              if (!eventData.date_start) {
                startDateError = true
              }
              if (!eventData.date_end) {
                endDateError = true
              }
              handleSubmit(onSubmit)
            }"
            @reset.prevent="() => {
              startDateError = false
              endDateError = false
              resetForm()
            }"
          >

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Заголовок"
              rules="required"
            >
              <b-form-group
                label="Заголовок"
                label-for="event-title"
              >
                <b-form-input
                  id="event-title"
                  v-model="eventData.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Название события"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Зал"
              rules="required"
            >

              <b-form-group
                label="Зал"
                label-for="hall_id"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventData.hall_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="halls"
                  :reduce="val => val.id"
                  :get-option-label="option => option.name"
                  :clearable="false"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Администратор"
              rules="required"
            >

              <b-form-group
                label="Администратор"
                label-for="admin_id"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventData.admin_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="admins"
                  :reduce="val => val.id"
                  :get-option-label="option => option.profile && option.profile.fio"
                  :clearable="false"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Возрастная категория"
              rules="required"
            >

              <b-form-group
                label="Возрастная категория"
                label-for="category_id"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventData.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categories"
                  :reduce="val => val.id"
                  :get-option-label="option => option.name"
                  :clearable="false"
                  @input="selectCategory"
                >

                  <template #option="{ color, name }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ name }}</span>
                  </template>

                  <template #selected-option="{ color, name }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ name }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Группа"
              rules="required"
            >

              <b-form-group
                label="Группа"
                label-for="group_id"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventData.group_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="groups"
                  :reduce="val => val.id"
                  :get-option-label="option => option.name"
                  :disabled="!eventData.category_id"
                  :clearable="false"
                  @input="selectGroup"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Тренер"
              rules="required"
            >

              <b-form-group
                label="Тренер"
                label-for="trainer_id"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventData.trainer_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="trainers"
                  :reduce="val => val.id"
                  :get-option-label="option => option.profile && option.profile.fio"
                  :clearable="false"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Start Date -->
            <div>

              <b-form-group
                label="Дата начала"
                label-for="start-date"
                :state="!startDateError"
              >
                <flat-pickr
                  v-model="eventData.date_start"
                  class="form-control"
                  :config="{ enableTime: true, locale: Russian }"
                  @input="inputDateStart"
                />
                <div
                  v-if="startDateError"
                  class="invalid-feedback d-block"
                > Поле Дата начала обязательно для заполнения </div>
              </b-form-group>
            </div>

            <!-- End Date -->
            <div>
              <b-form-group
                label="Дата окончания"
                label-for="end-date"
                :state="!endDateError"
              >
                <flat-pickr
                  v-model="eventData.date_end"
                  class="form-control"
                  :config="{ enableTime: true, locale: Russian }"
                  @input="inputDateEnd"
                />
                <div
                  v-if="endDateError"
                  class="invalid-feedback d-block"
                > Поле Дата окончания обязательно для заполнения </div>
              </b-form-group>
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ eventData.id ? $t('Update') : $t('Add') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('Reset') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <b-modal
      v-model="showModal"
      id="modal-event"
      :title="event.title"
      size="xl"
      hide-footer
    >
      <!-- Table Container Card -->
      <b-card>
        <div>
          <b-row>
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <div
                v-if="event.group"
                class="d-flex justify-content-start"
              >
                <div class="d-flex flex-column">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      Группа: {{ event.group.name }}
                    </h4>
                  </div>
                </div>
              </div>

              <!-- Stats -->
              <div
                class="d-flex align-items-center mt-2"
              >
                <div class="d-flex align-items-center">
                  <b-avatar
                    variant="light-success"
                    rounded
                  >
                    <feather-icon
                      icon="UsersIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{ totalUsers }}
                    </h5>
                    <small>Игроков в группе</small>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="6"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr v-if="event.admin">
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Администратор</span>
                  </th>
                  <td>
                    {{ event.admin.profile.fio }}
                  </td>
                </tr>
                <tr v-if="event.trainer">
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Тренер</span>
                  </th>
                  <td>
                    {{ event.trainer.profile.fio }}
                  </td>
                </tr>
                <tr v-if="event.category">
                  <th class="pb-50">
                    <feather-icon
                      icon="SmileIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Возрастная категория</span>
                  </th>
                  <td>
                    <b-badge :variant="event.category.color">{{ event.category.name }}</b-badge>
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="WatchIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Начало</span>
                  </th>
                  <td>
                    {{ event.date_start | moment('DD.MM.YYYY hh:mm') }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="WatchIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Окончание</span>
                  </th>
                  <td>
                    {{ event.date_end | moment('DD.MM.YYYY hh:mm') }}
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <div class="relative">
            <b-overlay
              :show="loading"
              no-wrap
              :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
            />
            <b-table
              ref="refUserListTable"
              class="position-relative mt-2"
              :items="fetchUsers"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              show-empty
              empty-text="Нет данных"
            >
            <template #cell(player.profile.fio)="data">
              <div v-if="data.field.detailsPage">
                <b-media
                  vertical-align="center"
                >
                  <template #aside>
                    <b-avatar
                      size="32"
                      :src="data.item.profile.avatar && `${server + data.item.player.profile.avatar}`"
                      :text="avatarText(data.item.player.profile.fio.replace(/[0-9]/g, ''))"
                    />
                  </template>
                  <b-link
                    v-if="data.field.to"
                    :to="{ name: `${data.field.detailsPage}`, params: { id: data.item.id } }"
                    class="font-weight-bold"
                  >
                    <span
                      v-html="data.item.player.profile.fio"
                    />
                  </b-link>
                  <b-link
                    v-if="data.field.href"
                    :href="`${data.field.detailsPage}/${data.item.id}`"
                    :target="data.field.target"
                    class="font-weight-bold"
                  >
                    <span
                      v-html="data.item.player.profile.fio"
                    />
                  </b-link>
                </b-media>
              </div>
              <div v-else>
                <b-media
                  vertical-align="center"
                >
                  <template #aside>
                    <b-form-checkbox
                      v-model="data.item.selected"
                      @change="toggle(data.item)"
                      class="mr-50"
                    />
                    <b-avatar
                      size="32"
                      :src="data.item.player.profile.avatar && `${server + data.item.player.profile.avatar}`"
                      :text="avatarText(data.item.player.profile.fio.replace(/[0-9]/g, ''))"
                    />
                  </template>
                  <span
                    v-html="data.item.player.profile.fio"
                  />
                </b-media>
              </div>
            </template>
            <template #cell(player.profile.category)="data">
            <div v-if="data.item.player.profile.category">
                <b-badge :variant="data.item.player.profile.category.color">{{ data.item.player.profile.category.name }}</b-badge>
              </div>
            </template>
            <template #cell(player.profile.dob)="data">
              <div>
                {{ data.item.player.profile.dob | moment('DD.MM.YYYY') }}
              </div>
            </template>

            </b-table>
          </div>
        </div>
        <div>
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ dataMeta.from }} - {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
      <hr
        style="margin-left:-1.4rem;margin-right:-1.4rem;"
      >
      <div class="d-flex mt-2 justify-content-end">
        <b-button
          variant="primary"
          @click="showModal = false"
        >
          Закрыть
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup,
  BFormInput, BButton, BFormInvalidFeedback,
  BModal, BOverlay, BCard, BTable, BRow, BCol,
  BPagination, BMedia, BAvatar, BLink, BBadge,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs, computed } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BPagination,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormCheckbox,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    isEventHandlerModalActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
    refetchEvents: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    const clearFormData = ref(null)

    const {
      eventData,
      resetEventData,

      halls,
      trainers,
      categories,
      groups,
      admins,
      players,

      fetchHalls,
      fetchTrainers,
      fetchCategories,
      fetchGroups,
      fetchAdmins,
      fetchPlayers,

      selectCategory,
      selectGroup,

      startDateError,
      endDateError,
      onSubmit,

      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      loading,
      fetchUsers,
      selectedItems,
      addEventVisitedUsers,
      toggle,
      refetchData,
      inputDateStart,
      inputDateEnd,
    } = useCalendarEventHandler(toRefs(props), clearFormData, props.refetchEvents, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventData, props.clearEventData)

    clearFormData.value = clearForm

    const showModal = computed({
      get() {
        return props.isEventHandlerModalActive
      },
      set(val) {
        emit('update:is-event-handler-modal-active', val)
      },
    })

    return {
      avatarText,
      // Add New Event
      eventData,
      startDateError,
      endDateError,
      onSubmit,

      halls,
      trainers,
      categories,
      groups,
      admins,
      players,

      fetchHalls,
      fetchTrainers,
      fetchCategories,
      fetchGroups,
      fetchAdmins,
      fetchPlayers,

      selectCategory,
      selectGroup,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
      Russian,

      showModal,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      fetchUsers,
      loading,
      selectedItems,
      addEventVisitedUsers,
      toggle,
      refetchData,

      inputDateStart,
      inputDateEnd,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
