<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="groupData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching group data
      </h4>
      <div class="alert-body">
        No group found with this group id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'groups'}"
        >
          Group List
        </b-link>
        for other groups.
      </div>
    </b-alert>

    <template v-if="groupData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <group-view-info-card :group-data="groupData" />
        </b-col>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <group-view-players-list
            :group="groupData"
            :refresh="refresh"
          />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import GroupViewInfoCard from './GroupViewInfoCard.vue'
import GroupViewPlayersList from './GroupViewPlayersList.vue'
import groupStoreModule from '../groupStoreModule'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    GroupViewInfoCard,
    GroupViewPlayersList,
  },
  props: {
    api: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const groupData = ref(null)

    const GROUP_APP_STORE_MODULE_NAME = 'app-group'

    // Register module
    if (!store.hasModule(GROUP_APP_STORE_MODULE_NAME)) store.registerModule(GROUP_APP_STORE_MODULE_NAME, groupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GROUP_APP_STORE_MODULE_NAME)) store.unregisterModule(GROUP_APP_STORE_MODULE_NAME)
    })

    const refresh = ref(false)
    store.dispatch('app-group/fetchGroup', {
      api: props.api,
      id: router.currentRoute.params.id,
    })
      .then(response => { groupData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          groupData.value = undefined
        }
      })

    return {
      groupData,
      refresh,
    }
  },
}
</script>

<style>

</style>
