        <template>
  <div
      >
    <b-row
          >
                                    <b-col
                                                                                                >
                            <calendar
    :queryParams="[&quot;partner_id&quot;,&quot;branche_id&quot;]"
  />            </b-col>
                            </b-row>
  </div>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BRow,
  BCol,
} from 'bootstrap-vue'


                                      import Calendar from '@core/components/calendar-events/Calendar.vue'
                            
export default {
  components: {
    BRow,
    BCol,

                                                          Calendar,
                                          },
  setup() {
    
      },
}
</script>
    