import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchGroup(ctx, { api, id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${api}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPlayers(ctx, { api, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(api, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUserGroup(ctx, { api, users }) {
      return new Promise((resolve, reject) => {
        axios
          .post(api, users)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
