<template>
  <b-card
    no-body
    class="card-attendance-table"
  >
    <b-card-header>
      <h6 class="text-muted">{{ title }}</h6>
    </b-card-header>
    <div
      v-if="loading"
      class="relative w-100 pb-4 pt-4"
    >
      <b-overlay
        :show="true"
        no-wrap
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
      />
    </div>
    <b-table
      v-if="attendanceData"
      :items="attendanceData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- city -->
      <template #cell(city)="data">
        <div>
          <div>
            <div>
              {{ data.item.city }}
            </div>
          </div>
        </div>
      </template>

      <!-- group -->
      <template #cell(group)="data">
        <div>
          <span>{{ data.item.group }}</span>
        </div>
      </template>

      <template #cell(category)="data">
        <div v-if="data.item.category">
          <b-badge :variant="data.item.category.color">{{ data.item.category.name }}</b-badge>
        </div>
      </template>

      <!-- trainer -->
      <template #cell(trainer)="data">
        <b-media
          v-if="data.item.trainer"
          vertical-align="center"
        >
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.trainer.profile.avatar && `${server + data.item.trainer.profile.avatar}`"
              :text="avatarText(data.item.trainer.profile.fio.replace(/[0-9]/g, ''))"
            />
          </template>
          <b-link
            class="font-weight-bold cursor-default"
            :disabled="true"
          >
            <span
              v-html="data.item.trainer.profile.fio"
            />
          </b-link>
        </b-media>
      </template>

      <!-- visits -->
      <template #cell(visits)="data">
        <div class="d-flex align-items-center">
          <span class="mr-1">{{ calculateVisits(data.item) }}</span>
          <feather-icon
            v-if="data.item.loss !== undefined"
            :icon="data.item.loss ? 'TrendingDownIcon':'TrendingUpIcon'"
            :class="data.item.loss ? 'text-danger':'text-success'"
          />
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BBadge,
  BCardHeader, BOverlay,
  BMedia, BAvatar, BLink,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { ref, watch } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BCardHeader,
    BOverlay,
    BMedia,
    BAvatar,
    BLink,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: () => '',
    },
    api: {
      type: String,
      required: false,
      default: null,
    },
    selectedFilter: {
      type: [Object, String, Number, null],
      default: null,
    },
  },
  setup(props) {
    const { route } = useRouter()
    const server = process.env.VUE_APP_SERVER

    const fields = [
      { key: 'branche.city', label: 'Город' },
      { key: 'name', label: 'Группа' },
      { key: 'category', label: 'Возрастная категория' },
      { key: 'trainer', label: 'Тренер' },
      { key: 'visits', label: '% посещаемости' },
    ]

    const attendanceData = ref(null)

    const loading = ref(false)

    const fetchData = () => {
      attendanceData.value = null
      if (props.api) {
        loading.value = true
        store.dispatch('app/fetchData', {
          api: props.api,
          params: {
            period: props.selectedFilter,
            partner_id: route.value.params.partner_id,
            branche_id: route.value.params.branche_id,
          },
        }).then(res => {
          const { data, success } = res.data

          if (success) {
            attendanceData.value = data
          }

          loading.value = false
        }).catch(() => {
          loading.value = false
        })
      }
    }

    fetchData()

    watch(() => props.selectedFilter, () => fetchData())
    watch([route], () => fetchData())

    /* eslint-disable */
    const trend = (arr, options) => {
      options = options || {}
      options.lastPoints = options.lastPoints || 1
      options.avgPoints = options.avgPoints || 10

      if (arr.length < options.lastPoints + options.avgPoints) return null

      let lastArr = options.reversed ? arr.slice(0, options.lastPoints) : arr.slice(arr.length - options.lastPoints, arr.length)
      let chartArr = options.reversed ? arr.slice(options.lastPoints, options.lastPoints+options.avgPoints) : arr.slice(arr.length - options.lastPoints - options.avgPoints, arr.length - options.lastPoints)

      let chartAvg = chartArr.reduce(function(res, val) { return res += val }) / chartArr.length
      let lastAvg = Math.max.apply(null, lastArr)

      if (options.avgMinimum !== undefined && chartAvg < options.avgMinimum) return null
      return lastAvg/chartAvg
    };
    /* eslint-enable */

    const calculateVisits = item => {
      if (!item.players || !item.players.length) {
        return '0%'
      }

      if (!item.events || !item.events.length) {
        return '0%'
      }

      const countPlayers = item.players.length

      let visitsCount = 0
      const visits = []

      item.events.forEach(event => {
        if (event.visited) {
          visitsCount += event.visited.length
          visits.push(event.visited.length)
        }
      })

      const growth = trend(visits, {
        lastPoints: 1,
        avgPoints: visits.length - 1,
        avgMinimum: 1,
        reversed: false,
      })

      if (growth < 1) {
        // eslint-disable-next-line
        item.loss = true
      } else {
        // eslint-disable-next-line
        item.loss = false
      }

      if (!visitsCount) {
        return '0%'
      }

      // eslint-disable-next-line
      return `${Math.trunc((visitsCount / visits.length) / countPlayers * 100)}%`
    }

    return {
      server,
      fields,
      attendanceData,
      loading,
      avatarText,
      calculateVisits,
      trend,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-attendance-table ::v-deep td .b-avatar.badge-light-attendance {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.cursor-default {
  cursor: default!important;
}
</style>
