        <template>
  <!-- Container Card -->
  <b-card>
    <b-row>
                                  <b-col
                                                                                                >
                            <create-update-sidebar
  title="Добавить платеж"
  api="http://api.bc-sport.ru/api/finances"
    :queryParams="[&quot;partner_id&quot;,&quot;branche_id&quot;]"
    :refresh.sync="refresh"
  :fields="[{&quot;name&quot;:&quot;user_id&quot;,&quot;label&quot;:&quot;\u0418\u0433\u0440\u043e\u043a&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;v-select&quot;,&quot;value&quot;:&quot;&quot;,&quot;option&quot;:&quot;name&quot;,&quot;reduce&quot;:&quot;id&quot;,&quot;rules&quot;:&quot;required&quot;,&quot;options&quot;:[],&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/players&quot;},{&quot;name&quot;:&quot;admin_id&quot;,&quot;label&quot;:&quot;\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;v-select&quot;,&quot;value&quot;:&quot;&quot;,&quot;option&quot;:&quot;name&quot;,&quot;reduce&quot;:&quot;id&quot;,&quot;rules&quot;:&quot;required&quot;,&quot;options&quot;:[],&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/admins&quot;},{&quot;name&quot;:&quot;subscription_id&quot;,&quot;label&quot;:&quot;\u0410\u0431\u043e\u043d\u0435\u043c\u0435\u043d\u0442&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;v-select&quot;,&quot;value&quot;:&quot;&quot;,&quot;option&quot;:&quot;name&quot;,&quot;reduce&quot;:&quot;id&quot;,&quot;rules&quot;:&quot;required&quot;,&quot;options&quot;:[],&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/subscriptions&quot;},{&quot;name&quot;:&quot;receipt_type_id&quot;,&quot;label&quot;:&quot;\u0422\u0438\u043f \u043f\u043e\u0441\u0442\u0443\u043f\u043b\u0435\u043d\u0438\u044f&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;v-select&quot;,&quot;value&quot;:&quot;&quot;,&quot;option&quot;:&quot;name&quot;,&quot;reduce&quot;:&quot;id&quot;,&quot;rules&quot;:&quot;required&quot;,&quot;options&quot;:[],&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/receipttypes&quot;}]"
  :is-sidebar-active.sync="isSidebarActive"
/>            </b-col>
                                        <b-col
                                                                                                >
                            <update-sidebar
  title="Редактировать платеж"
  api="http://api.bc-sport.ru/api/finances"
  :apiId="editSidebarId"
  getApi="http://api.bc-sport.ru/api/finances"
    :queryParams="[&quot;partner_id&quot;,&quot;branche_id&quot;]"
    :refresh.sync="refresh"
  :fields="[{&quot;name&quot;:&quot;user_id&quot;,&quot;label&quot;:&quot;\u0418\u0433\u0440\u043e\u043a&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;v-select&quot;,&quot;option&quot;:&quot;name&quot;,&quot;reduce&quot;:&quot;id&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;,&quot;options&quot;:[],&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/players&quot;},{&quot;name&quot;:&quot;admin_id&quot;,&quot;label&quot;:&quot;\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;v-select&quot;,&quot;option&quot;:&quot;name&quot;,&quot;reduce&quot;:&quot;id&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;,&quot;options&quot;:[],&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/admins&quot;},{&quot;name&quot;:&quot;subscription_id&quot;,&quot;label&quot;:&quot;\u0410\u0431\u043e\u043d\u0435\u043c\u0435\u043d\u0442&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;v-select&quot;,&quot;option&quot;:&quot;name&quot;,&quot;reduce&quot;:&quot;id&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;,&quot;options&quot;:[],&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/subscriptions&quot;},{&quot;name&quot;:&quot;receipt_type_id&quot;,&quot;label&quot;:&quot;\u0422\u0438\u043f \u043f\u043e\u0441\u0442\u0443\u043f\u043b\u0435\u043d\u0438\u044f&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;v-select&quot;,&quot;option&quot;:&quot;name&quot;,&quot;reduce&quot;:&quot;id&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;,&quot;options&quot;:[],&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/receipttypes&quot;}]"
  :is-sidebar-active.sync="editSidebarRef"
/>            </b-col>
                            </b-row>
        <b-tabs
      ref="my-tabs"
      v-model="currentTab"
      pills
      nav-class="scrollable-tabs-nav"
    >
              <b-tab
                              title-item-class="text-nowrap"
        >
          <template #title>
            <feather-icon
              icon="ClipboardIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">История операций</span>
          </template>
          <div>
            <b-row
                          >
                                                                    <b-col
                                                                                                                                      sm="12"
                                                                  >
                        <div
                                                    class="d-flex justify-content-end mb-2"
                                                  >
                                                      <div
                                                            >
                                                                <open-sidebar-button
  label="Поступление"
  :sidebar.sync="isSidebarActive"
    variant="primary"
      />                            </div>
                                                  </div>
                    </b-col>
                                                                        <b-col
                                                                                                                                      sm="12"
                                                                >
                                            <basic-table
  :columns="[{&quot;key&quot;:&quot;player.profile.fio&quot;,&quot;label&quot;:&quot;\u0418\u0433\u0440\u043e\u043a&quot;,&quot;sortable&quot;:true,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;subscription.subscription_type&quot;,&quot;label&quot;:&quot;\u0410\u0431\u043e\u043d\u0435\u043c\u0435\u043d\u0442&quot;,&quot;sortable&quot;:true,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;price&quot;,&quot;label&quot;:&quot;\u0426\u0435\u043d\u0430&quot;,&quot;sortable&quot;:true,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;receipt_type.name&quot;,&quot;label&quot;:&quot;\u0422\u0438\u043f \u043f\u043e\u0441\u0442\u0443\u043f\u043b\u0435\u043d\u0438\u044f&quot;,&quot;sortable&quot;:true,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;admin.profile.fio&quot;,&quot;label&quot;:&quot;\u0410\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440&quot;,&quot;sortable&quot;:true,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;created_at&quot;,&quot;label&quot;:&quot;\u0414\u0430\u0442\u0430 \u043f\u043b\u0430\u0442\u0435\u0436\u0430&quot;,&quot;sortable&quot;:true,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;actions&quot;,&quot;label&quot;:&quot;&quot;,&quot;sortable&quot;:false,&quot;tdClass&quot;:&quot;text-right&quot;,&quot;actions&quot;:[{&quot;label&quot;:&quot;\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c&quot;,&quot;name&quot;:&quot;Edit&quot;,&quot;icon&quot;:&quot;EditIcon&quot;,&quot;alert&quot;:false},{&quot;label&quot;:&quot;\u0423\u0434\u0430\u043b\u0438\u0442\u044c&quot;,&quot;icon&quot;:&quot;TrashIcon&quot;,&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/finances&quot;,&quot;method&quot;:&quot;delete&quot;}]}]"
      :queryParams="[&quot;partner_id&quot;,&quot;branche_id&quot;]"
        :refresh="refresh"
      :editSidebarRef.sync="editSidebarRef"
                        :brancheId="branche_id"
              :editSidebarId.sync="editSidebarId"
    api="http://api.bc-sport.ru/api/finances"
          />                    </b-col>
                                                            </b-row>
          </div>
        </b-tab>
          </b-tabs>
      </b-card>
</template>

<script>
/* eslint-disable */
// prettier-ignore
import {
  BTab, BTabs, BCard, BRow,
  BCol, VBModal,
} from 'bootstrap-vue'

import { ref, watch } from '@vue/composition-api'

                                      import CreateUpdateSidebar from '@core/components/sidebar/CreateUpdateSidebar.vue'
                                                              import UpdateSidebar from '@core/components/sidebar/UpdateSidebar.vue'
                            
                                                                                                    import OpenSidebarButton from '@core/components/button/OpenSidebarButton.vue'
                                                                                                                                            import BasicTable from '@core/components/table/basic-table/BasicTable.vue'
                                                                
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
                                                          CreateUpdateSidebar,
                                                                              UpdateSidebar,
                                                                                                                                                        OpenSidebarButton,
                                                                                                                                BasicTable,
                                                                        },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      isMouseDown: false,
      startX: null,
      scrollLeft: null,
      navElement: null,
    }
  },
  mounted() {
    this.navElement = this.$refs['my-tabs'].$el.querySelector('ul')

    this.navElement.addEventListener('mousedown', this.onMouseDown)
    this.navElement.addEventListener('mouseup', this.onMouseUp)
    this.navElement.addEventListener('mousemove', this.onMouseMove)
    this.navElement.addEventListener('mouseleave', this.onMouseLeave)

    this.navElement.addEventListener('touchstart', this.onTouchStart)
    this.navElement.addEventListener('touchmove', this.onTouchMove)
  },
  onBeforeDestroy() {
    this.navElement.removeEventListener('mousedown', this.onMouseDown)
    this.navElement.removeEventListener('mouseup', this.onMouseUp)
    this.navElement.removeEventListener('mousemove', this.onMouseMove)
    this.navElement.removeEventListener('mouseleave', this.onMouseLeave)

    this.navElement.removeEventListener('touchstart', this.onMouseDown)
    this.navElement.removeEventListener('touchmove', this.onMouseMove)
  },
  methods: {
    // Scrolling tabs methods
    onTouchStart(event) {
      event.stopPropagation()
      this.startX = event.touches[0].pageX - this.navElement.offsetLeft
      this.scrollLeft = this.navElement.scrollLeft
    },
    onTouchMove(event) {
      event.stopPropagation()
      event.preventDefault()
      const x = event.touches[0].pageX - this.navElement.offsetLeft
      const speed = 1 // higher number means faster scrolling
      const walk = (x - this.startX) * speed
      this.navElement.scrollLeft = this.scrollLeft - walk
    },
    onMouseDown(event) {
      event.stopPropagation()
      event.preventDefault()
      this.isMouseDown = true

      this.startX = event.pageX - this.navElement.offsetLeft
      this.scrollLeft = this.navElement.scrollLeft
    },
    onMouseUp(event) {
      event.stopPropagation()
      event.preventDefault()
      this.isMouseDown = false
    },
    onMouseLeave(event) {
      event.stopPropagation()
      event.preventDefault()
      this.isMouseDown = false
    },
    onMouseMove(event) {
      if (!this.isMouseDown) return

      event.stopPropagation()
      event.preventDefault()
      const x = event.pageX - this.navElement.offsetLeft
      const speed = 1.5 // higher number means faster scrolling
      const walk = (x - this.startX) * speed
      this.navElement.scrollLeft = this.scrollLeft - walk
    },
  },
  setup() {
                const isSidebarActive = ref(false)
            const editSidebarRef = ref(false)
            const editSidebarId = ref(null)
            const branche_id = ref(undefined)
            const refresh = ref(false)
            const currentTab = ref()
          
    return {
                          isSidebarActive,
                  editSidebarRef,
                  editSidebarId,
                  branche_id,
                  refresh,
                  currentTab,
                  }
  },
}
</script>
<style lang="scss">
.scrollable-tabs-nav {
  flex-wrap: nowrap;
  overflow: hidden;
}
</style>
    