import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [],
    selectedCalendars: ['1', '2', '3', '4'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_CALENDAR_OPRIONS(state, val) {
      state.calendarOptions = val
    },
  },
  actions: {
    fetchBranches(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('branchs', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchHalls(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('halls', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTrainers(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('trainers', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategories(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('agecategorys', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroups(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('groups', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAdmins(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('admins', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPlayers(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('players', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupPlayers(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('usergroups', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEvents(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('events', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('events', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`events/${payload.id}`, payload.event)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      // eslint-disable-next-line no-underscore-dangle
      return this._vm.$swal({
        text: 'Удалить событие?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios.delete(`events/${id}`)
            .then(response => response)
        },
      }).then(result => {
        if (result.isConfirmed) {
          const { message } = result.value.data

          // eslint-disable-next-line no-underscore-dangle
          this._vm.$swal({
            icon: 'success',
            text: message,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })

          return true
        }

        return false
      })
    },
    addEventVisitedUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('events-add-visited-user', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEventVisitedUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('events-remove-visited-user', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
