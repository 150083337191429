        <template>
  <b-card>
    <b-row
          >
                                    <b-col
                                                                                      sm="12"
                                        >
              <div
                                class="d-flex justify-content-end mb-2"
                              >
                                  <div
                                        >
                                            <open-sidebar-button
  label="Добавить администратора"
  :sidebar.sync="isSidebarActive"
    variant="primary"
      />                  </div>
                              </div>
            </b-col>
                                        <b-col
                                                                                                >
                            <create-update-sidebar
  title="Добавить администратора"
  api="http://api.bc-sport.ru/api/admins"
    :queryParams="[&quot;partner_id&quot;,&quot;branche_id&quot;]"
    :refresh.sync="refresh"
  :fields="[{&quot;name&quot;:&quot;fio&quot;,&quot;label&quot;:&quot;\u0424\u0418\u041e&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;text&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;},{&quot;name&quot;:&quot;city&quot;,&quot;label&quot;:&quot;\u0413\u043e\u0440\u043e\u0434&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;text&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;},{&quot;name&quot;:&quot;dob&quot;,&quot;label&quot;:&quot;\u0414\u0430\u0442\u0430 \u0440\u043e\u0436\u0434\u0435\u043d\u0438\u044f&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;date&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;},{&quot;name&quot;:&quot;phone&quot;,&quot;label&quot;:&quot;\u0422\u0435\u043b\u0435\u0444\u043e\u043d&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;phone&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required&quot;},{&quot;name&quot;:&quot;email&quot;,&quot;label&quot;:&quot;Email \u0434\u043b\u044f \u0432\u0445\u043e\u0434\u0430&quot;,&quot;placeholder&quot;:&quot;&quot;,&quot;type&quot;:&quot;email&quot;,&quot;value&quot;:&quot;&quot;,&quot;rules&quot;:&quot;required|email&quot;}]"
  :is-sidebar-active.sync="isSidebarActive"
/>            </b-col>
                                        <b-col
                                                                                      sm="12"
                                        >
                            <basic-table
  :columns="[{&quot;key&quot;:&quot;profile.fio&quot;,&quot;label&quot;:&quot;\u0424\u0418\u041e&quot;,&quot;sortable&quot;:false,&quot;thClass&quot;:&quot;text-left&quot;,&quot;detailsPage&quot;:&quot;admins-view&quot;,&quot;to&quot;:true},{&quot;key&quot;:&quot;profile.city&quot;,&quot;label&quot;:&quot;\u0413\u043e\u0440\u043e\u0434&quot;,&quot;sortable&quot;:false,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;profile.dob&quot;,&quot;label&quot;:&quot;\u0414\u0430\u0442\u0430 \u0440\u043e\u0436\u0434\u0435\u043d\u0438\u044f&quot;,&quot;sortable&quot;:false,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;profile.phone&quot;,&quot;label&quot;:&quot;\u0422\u0435\u043b\u0435\u0444\u043e\u043d&quot;,&quot;sortable&quot;:false,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;profile.email&quot;,&quot;label&quot;:&quot;Email&quot;,&quot;sortable&quot;:true,&quot;thClass&quot;:&quot;text-left&quot;},{&quot;key&quot;:&quot;actions&quot;,&quot;label&quot;:&quot;&quot;,&quot;sortable&quot;:false,&quot;tdClass&quot;:&quot;text-right&quot;,&quot;actions&quot;:[{&quot;label&quot;:&quot;\u0423\u0434\u0430\u043b\u0438\u0442\u044c&quot;,&quot;icon&quot;:&quot;TrashIcon&quot;,&quot;api&quot;:&quot;http:\/\/api.bc-sport.ru\/api\/admins&quot;,&quot;method&quot;:&quot;delete&quot;}]}]"
      :queryParams="[&quot;partner_id&quot;,&quot;branche_id&quot;]"
        :refresh="refresh"
                          :brancheId="branche_id"
              api="http://api.bc-sport.ru/api/admins"
          />            </b-col>
                            </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'

                                                    import OpenSidebarButton from '@core/components/button/OpenSidebarButton.vue'
                                                                          import CreateUpdateSidebar from '@core/components/sidebar/CreateUpdateSidebar.vue'
                                                              import BasicTable from '@core/components/table/basic-table/BasicTable.vue'
                            
export default {
  components: {
    BCard,
    BRow,
    BCol,

                                                                          OpenSidebarButton,
                                                                                            CreateUpdateSidebar,
                                                                              BasicTable,
                                          },
  setup() {
                const isSidebarActive = ref(false)
            const branche_id = ref(undefined)
            const refresh = ref(false)
          
          return {
            isSidebarActive,
            branche_id,
            refresh,
            }
      },
}
</script>
    